import PropTypes from 'prop-types'
import { Box, Typography, Tooltip } from '@mui/material'
import Icon from './Icon/Icon'

const FHNToolTip = ({ message, tooltipLabel, color, icon, iconAfter, sx }) => {
  return (
    <Tooltip
      sx={{ lineHeight: 0.5 }}
      title={<Typography variant="caption">{message}</Typography>}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: 'fit-content',
          ...sx,
        }}
        variant="text"
      >
        {icon}
        {!iconAfter && (
          <Icon
            sx={{ color: color ? color : 'surface.on-surface', fontSize: 16 }}
          >
            help
          </Icon>
        )}
        <Typography
          sx={{
            color: color ? color : 'surface.on-surface',
            fontWeight: '400',
            ml: 1,
            textTransform: 'capitalize',
          }}
          variant="subtitle1"
        >
          {tooltipLabel}
        </Typography>
        {iconAfter && (
          <Icon
            sx={{
              color: color ? color : 'surface.on-surface',
              fontSize: 16,
              ml: 1,
            }}
          >
            help
          </Icon>
        )}
      </Box>
    </Tooltip>
  )
}

FHNToolTip.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.node,
  iconAfter: PropTypes.bool,
  message: PropTypes.string,
  sx: PropTypes.object,
  tooltipLabel: PropTypes.string,
}

export default FHNToolTip
