// import Head from 'next/head'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

const HeadComponent = ({ school = null }) => (
  <>
    <Helmet>
      <meta charSet="UTF-8" />
      <meta content="origin" name="referrer" />
      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta content="yes" name="mobile-web-app-capable" />
      <meta
        content={prop('triage_meta_description', school)}
        property="og:description"
      />
      <meta content={prop('triage_meta_title', school)} property="og:title" />
      <meta content={prop('triage_meta_image', school)} property="og:img" />

      <title>{prop('triage_meta_title', school)}</title>

      <link
        as="font"
        crossOrigin="true"
        href="/fonts/MarkOT.woff"
        rel="preload"
        type="font/woff"
      />
      <link
        as="font"
        crossOrigin="true"
        href="/fonts/MarkOT-Medium.woff"
        rel="preload"
        type="font/woff"
      />
      <link
        as="font"
        crossOrigin="true"
        href="/fonts/MarkOT-Bold.woff"
        rel="preload"
        type="font/woff"
      />

      <link
        href="/favicon/apple-icon-57x57.png"
        rel="apple-touch-icon"
        sizes="57x57"
      />
      <link
        href="/favicon/apple-icon-60x60.png"
        rel="apple-touch-icon"
        sizes="60x60"
      />
      <link
        href="/favicon/apple-icon-72x72.png"
        rel="apple-touch-icon"
        sizes="72x72"
      />
      <link
        href="/favicon/apple-icon-76x76.png"
        rel="apple-touch-icon"
        sizes="76x76"
      />
      <link
        href="/favicon/apple-icon-114x114.png"
        rel="apple-touch-icon"
        sizes="114x114"
      />
      <link
        href="/favicon/apple-icon-120x120.png"
        rel="apple-touch-icon"
        sizes="120x120"
      />
      <link
        href="/favicon/apple-icon-144x144.png"
        rel="apple-touch-icon"
        sizes="144x144"
      />
      <link
        href="/favicon/apple-icon-152x152.png"
        rel="apple-touch-icon"
        sizes="152x152"
      />
      <link
        href="/favicon/apple-icon-180x180.png"
        rel="apple-touch-icon"
        sizes="180x180"
      />
      <link
        href="/favicon/android-icon-192x192.png"
        rel="icon"
        sizes="192x192"
        type="image/png"
      />
      <link
        href="/favicon/favicon-32x32.png"
        rel="icon"
        sizes="32x32"
        type="image/png"
      />
      <link
        href="/favicon/favicon-96x96.png"
        rel="icon"
        sizes="96x96"
        type="image/png"
      />
      <link
        href="/favicon/favicon-16x16.png"
        rel="icon"
        sizes="16x16"
        type="image/png"
      />
      <link href="/favicon/manifest.json" rel="manifest" />

      <meta content="#ffffff" name="msapplication-TileColor" />
      <meta
        content="/favicon/ms-icon-144x144.png"
        name="msapplication-TileImage"
      />
      <meta
        content="qfpd6xykamy8zz2wme9hlnjt0hqbfx"
        name="facebook-domain-verification"
      />

      <meta content="#ffffff" name="theme-color" />

      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
    </Helmet>

    {/* <script
        async
        defer
        id="google-platform"
        nonce={cspNonce}
        src="https://apis.google.com/js/platform.js"
      /> */}
    {/* <Script id="google-analytics" nonce={cspNonce}>
        {`
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
        `}
      </Script> */}
    {/* {googleTagManagerEnable && (
        <>
          <Script id="google-tag-manager" nonce={cspNonce}>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
              n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${googleTagManagerId}')
            `}
          </Script>
          <noscript>
            <iframe
              height="0"
              src={`https://www.googletagmanager.com/ns.html?id={${googleTagManagerId}}`}
              style={{ display: 'none', visibility: 'hidden' }}
              width="0"
            ></iframe>
          </noscript>
        </>
      )} */}
  </>
)

HeadComponent.propTypes = {
  school: PropTypes.shape({}),
}

export default HeadComponent
