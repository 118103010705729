import React, { useContext, useEffect, useState } from 'react'
import Needs from '../../Needs'
import { Stack, Box, Typography, Grid } from '@mui/material'
import { join, pluck } from 'ramda'
import PropTypes from 'prop-types'
import SchoolLogoImage from '../../SchoolLogoImage'
import SelectAllInput from '../SelectAllInput'
import ErrorBadge from '../../ErrorBadge'
import { QuestionContext } from '../../../contexts/questionContext'
import { useLocation } from 'react-router-dom'

const NeedsQuestion = ({
  displayErrorMessage,
  triage_logo,
  questionDescription,
  setDisplayErrorMessage,
  searchParams,
  categories,
}) => {
  const { previousNeeds, setPreviousNeeds, seeAllTopics, setSeeAllTopics } =
    useContext(QuestionContext)
  const [selectedNeeds, setSelectedNeeds] = useState(previousNeeds)
  const location = useLocation()
  useEffect(() => {
    // if we have previous needs, we need to add them to the query so the next button press
    // will update the route properly
    searchParams.set('needs', join(',', pluck('id', previousNeeds)))
    setSeeAllTopics(false)
  }, [])

  const handleSeeAllTopicsChange = () => {
    if (seeAllTopics) {
      // unselected all needs
      // reset needs to blank so that we can select new ones and that error case triggers
      // since no needs in the query atm
      searchParams.set('needs', '')
    } else {
      //  selected all needs
      // remove old needs from the context and set the needs in router as 'none' so we
      // apply them all in the api call
      setSelectedNeeds([])
      setPreviousNeeds([])
      searchParams.set('needs', 'none')
    }
    setSeeAllTopics(!seeAllTopics)
    setDisplayErrorMessage(false)
  }

  const onChange = need => {
    let needs = selectedNeeds
    // todo - this can be ramdified with an assoc im pretty sure
    if (needs.find(n => n.id === need.id)) {
      needs = needs.filter(n => n.id !== need.id)
    } else {
      needs.push(need)
    }
    // we map the needs to the router query and then apply them to the route
    // above this in the Question component using
    searchParams.set('needs', join(',', pluck('id', needs)))
    setSelectedNeeds([...needs])
    setPreviousNeeds([...needs])
    setSeeAllTopics(false)
    setDisplayErrorMessage(false)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={11.5}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h2">
              {location.pathname.includes('/them')
                ? 'Life happens. What type of support do you think this person might need?'
                : 'Life happens. What type of support are you seeking?'}
            </Typography>
          </Box>
          {questionDescription && (
            <Box>
              <Typography variant="subtitle1">{questionDescription}</Typography>
            </Box>
          )}
          <Box>
            <Typography variant="subtitle1">
              Select all options that apply.
            </Typography>
          </Box>
          <Needs
            categories={categories}
            onChange={onChange}
            selectedNeeds={pluck('id', selectedNeeds)}
          />
        </Stack>
        <SelectAllInput
          handleSeeAllTopicsChange={handleSeeAllTopicsChange}
          label="See Resources for All Topics"
          seeAllTopics={seeAllTopics}
          tooltip="If you select See Resources for All Topics, we will provide all resources that match answers to your previous questions only. This question will be skipped."
        />
        {displayErrorMessage && (
          <ErrorBadge
            message={
              'Select an option or choose “see resources for all topics” before you continue.'
            }
          />
        )}
      </Grid>
    </Grid>
  )
}
NeedsQuestion.propTypes = {
  categories: PropTypes.array,
  displayErrorMessage: PropTypes.bool,
  questionDescription: PropTypes.string,
  setDisplayErrorMessage: PropTypes.func,
  searchParams: PropTypes.object,
  triage_logo: PropTypes.string,
}

NeedsQuestion.defaultProps = {
  categories: [],
}

export default NeedsQuestion
