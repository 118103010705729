import PropTypes from 'prop-types'
import QuestionNavItem from './QuestionNavItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import { equals, inc, map, range } from 'ramda'
import {
  Grid,
  List,
  Stack,
  Typography,
  Box,
  Button,
  ListItem,
} from '@mui/material'
import Icon from 'components/Icon/Icon'

const QuestionNavSidebar = ({
  handleBackButton,
  progressStep,
  progressStepText,
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const totalSteps = 3

  return isMobile ? (
    <Stack
      container
      direction="column"
      spacing={0}
      sx={{ alignItems: 'center', my: 1 }}
    >
      <Button
        onClick={handleBackButton}
        startIcon={<Icon>arrow_back</Icon>}
        variant="back-button"
      >
        Go Back
      </Button>
      <List sx={{ display: 'flex', width: '85%' }}>
        {map(
          step => (
            <ListItem
              key={step}
              sx={{
                borderBottom: theme =>
                  `6px solid ${
                    equals(progressStep, step)
                      ? theme.palette.buttons.primary['background-default']
                      : theme.palette.surface['container-high']
                  }`,
                mx: 0,
              }}
            />
          ),
          range(0, totalSteps)
        )}
      </List>
      <Typography
        sx={{ color: 'surface.on-surface', fontWeight: 400 }}
        variant="h5"
      >
        Step {inc(progressStep)}: {progressStepText[progressStep]}
      </Typography>
    </Stack>
  ) : (
    <Box
      sx={{
        display: 'flex',
        minHeight: '89vh',
        ml: 1,
        mt: 5,
      }}
    >
      <Stack spacing={50} sx={{ mb: 2 }}>
        <List>
          <Stack spacing={1}>
            {map(
              step => (
                <QuestionNavItem
                  active={progressStep === step}
                  key={step}
                  step={inc(step)}
                  text={progressStepText[step]}
                />
              ),
              range(0, totalSteps)
            )}
          </Stack>
        </List>
      </Stack>
    </Box>
  )
}

QuestionNavSidebar.propTypes = {
  handleBackButton: PropTypes.func,
  progressStep: PropTypes.number,
  progressStepText: PropTypes.array,
}

export default QuestionNavSidebar
