import PropTypes from 'prop-types'
import { Tooltip, Box, Typography, Checkbox, Stack } from '@mui/material'
import Icon from 'components/Icon/Icon'

const SelectAllInput = ({
  handleSeeAllTopicsChange,
  label,
  selectAll,
  tooltip,
}) => {
  return (
    <Tooltip
      aria-label={tooltip}
      sx={{ lineHeight: 0.5 }}
      title={<Typography variant="caption">{tooltip}</Typography>}
    >
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        spacing={1}
        sx={{ alignItems: 'center', mt: 2 }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: { xs: 'block', md: 'flex' },
            ml: 1,
            mt: 3.5,
          }}
        >
          <Box
            sx={{
              ':hover': {
                backgroundColor: 'buttons.tonal.background-hover',
              },
              backgroundColor: 'buttons.tonal.background-default',
              borderRadius: 100,
              pr: 2,
            }}
          >
            <Checkbox
              checked={selectAll}
              id="select-all"
              name="select-all"
              onChange={handleSeeAllTopicsChange}
              sx={{
                '&.Mui-checked': {
                  color: 'buttons.tonal.on-surface',
                },
                color: 'buttons.tonal.on-surface',
              }}
              value={selectAll}
            ></Checkbox>
            <Typography
              component={'label'}
              htmlFor="select-all"
              sx={{ ml: 1 }}
              variant="subtitle1"
            >
              {label}
            </Typography>
          </Box>
          <Stack
            direction={'row'}
            sx={{
              alignItems: 'center',
              mt: { xs: 2 },
              pb: 2,
            }}
          >
            <Icon
              sx={{
                color: 'grey.2',
                fontSize: 16,
                ml: { xs: 0, lg: '24px' },
                mr: 1,
              }}
            >
              help
            </Icon>
            <Typography component={'span'} variant="subtitle1">
              What Does This Mean?
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Tooltip>
  )
}
SelectAllInput.propTypes = {
  handleSeeAllTopicsChange: PropTypes.func,
  label: PropTypes.string,
  selectAll: PropTypes.bool,
  tooltip: PropTypes.string,
}

export default SelectAllInput
