import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Chip,
} from '@mui/material'
import Icon from './Icon/Icon'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import {
  always,
  cond,
  equals,
  filter,
  includes,
  isEmpty,
  length,
  not,
} from 'ramda'

const Needs = ({ categories, onChange, selectedNeeds }) => {
  const getIcons = cond([
    [equals('Physical Safety'), always({ fill: true, icon: 'favorite' })],
    [equals('Basic Needs'), always({ fill: false, icon: 'payments' })],
    [equals('Mental Health'), always({ fill: false, icon: 'psychology' })],
    [equals('Physical Health'), always({ fill: false, icon: 'monitor_heart' })],
    [equals('Incident Reporting'), always({ fill: false, icon: 'problem' })],
    [equals('Conflict Resolution'), always({ fill: false, icon: 'forum' })],
    [equals('Guidance or Advocacy'), always({ fill: true, icon: 'group' })],
  ])
  return (
    <Box>
      {categories.map((category, index) => {
        return (
          <Accordion
            disableGutters={true}
            key={index}
            square={true}
            sx={{
              ':before': {
                display: 'none',
              },
              ':expanded': {
                backgroundColor: 'red',
              },
              backgroundColor: 'surface.container',
              borderRadius: '16px',
              mb: 1,
              mt: 1,
            }}
          >
            <AccordionSummary
              expandIcon={<Icon fill={true}>keyboard_arrow_down</Icon>}
              key={'accordion-item-' + index}
            >
              <Box sx={{ alignItems: 'center', display: 'flex' }}>
                <Icon
                  fill={
                    not(isEmpty(getIcons(category.name).fill))
                      ? getIcons(category.name).fill
                      : true
                  }
                  sx={{ fontSize: 16, mr: 1 }}
                >
                  {category.icon_key
                    ? category.icon_key
                    : getIcons(category.name).icon}
                </Icon>
                <Typography variant="h4">{category.name}</Typography>
                {
                  <Typography sx={{ pl: 0.5 }} variant="h4">
                    (
                    {length(
                      filter(
                        need => includes(need.id, selectedNeeds),
                        category.needs
                      )
                    )}
                    )
                  </Typography>
                }
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {category.needs.map((need, needsIndex) => (
                <Chip
                  id={needsIndex}
                  key={needsIndex}
                  label={need.name}
                  onClick={() => onChange({ category: category.name, ...need })}
                  sx={
                    includes(need.id, selectedNeeds)
                      ? {
                          ':focus': {
                            backgroundColor: 'palette.surface.container-high',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                          },
                          backgroundColor: 'buttons.tonal.dark.on-surface',
                          border: 'none',
                          borderRadius: '8px',
                          fontFamily: 'aktiv-grotesk, sans-serif',
                          fontSize: 14,
                          fontWeight: 400,
                          mb: 1,
                          mr: 1,
                        }
                      : {
                          ':focus': {
                            backgroundColor: 'palette.surface.container-high',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                          },
                          backgroundColor: 'surface.container-low',
                          border: 'none',
                          borderRadius: '8px',
                          fontFamily: 'aktiv-grotesk, sans-serif',
                          fontSize: 14,
                          mb: 1,
                          mr: 1,
                        }
                  }
                  variant={
                    includes(need.id, selectedNeeds) ? 'filled' : 'outlined'
                  }
                />
              ))}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )
}

Needs.propTypes = {
  categories: PropTypes.array,
  onChange: PropTypes.func,
  selectedNeeds: PropTypes.array,
}

Needs.defaultProps = {
  categories: [],
}

export default Needs
