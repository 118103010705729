/* eslint-disable sort-keys */
import sendAnalytics from '../utils/sendAnalytics'
import { Box, Button, Link, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
// import LanguageIcon from '@mui/icons-material/Language'
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from './Icon/Icon'

const Footer = ({ school }) => {
  const { pathname } = useLocation()
  const year = new Date().getYear() + 1900
  const navigate = useNavigate()

  return (
    <>
      <Stack
        component="footer"
        direction={{
          xs: school.link_to_you ? 'column' : 'column-reverse',
          lg: 'row',
        }}
        sx={{
          backgroundColor: 'surface.surface',
          color: 'surface.on-surface',
          justifyContent: 'space-between',
          mt: 0,
          pb: 2,
          pt: 2,
        }}
      >
        <Stack spacing={1} sx={{ pl: 4 }}>
          <Box sx={{ pb: 1 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'aktiv-grotesk, sans-serif',
                mb: 1,
              }}
            >
              <Icon sx={{ mr: 1 }}>encrypted</Icon>
              <Typography
                component="span"
                sx={{ fontWeight: 500 }}
                variant="h6"
              >
                This tool is 100% anonymous.
              </Typography>
            </Box>
            <Typography sx={{ fontSize: '14px' }} variant="caption">
              No personal information is stored or shared, and you cannot be
              identified.
            </Typography>
          </Box>
          <Typography sx={{ fontSize: '12px' }} variant="caption">
            © {year} Grit Digital Health, LLC. All rights reserved. Grit does
            not claim copyright in the third party materials included on this
            site.
          </Typography>
          <Box
            sx={{
              color: 'buttons.primary.background-default',
              fontFamily: 'aktiv-grotesk, sans-serif',
              fontSize: '12px',
            }}
          >
            <Typography sx={{ fontSize: '12px' }} variant="caption">
              <Button
                onClick={() => {
                  navigate('/privacy-policy')
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'Privacy Policy',
                    path: pathname,
                  })
                }}
                sx={{
                  fontSize: '14px',
                  textTransform: 'none',
                  fontFamily: 'aktiv-grotesk',
                }}
                variant=""
              >
                Privacy Policy
              </Button>
              <Button
                onClick={() => {
                  navigate('/terms-and-conditions')
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'Terms of Use',
                    link_url: '/terms-and-conditions',
                    path: pathname,
                  })
                }}
                sx={{
                  fontSize: '14px',
                  textTransform: 'none',
                  fontFamily: 'aktiv-grotesk',
                }}
                variant=""
              >
                Terms of Use
              </Button>
              <Button
                onClick={() => {
                  navigate('/accessibility')
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'Accessibility',
                    link_url: '/accessibility',
                    path: pathname,
                  })
                }}
                sx={{
                  fontSize: '14px',
                  textTransform: 'none',
                  fontFamily: 'aktiv-grotesk',
                }}
                variant=""
              >
                Accessibility
              </Button>
            </Typography>
            <Button
              href="https://forms.monday.com/forms/4823e24a3640698828ff434d04338067?r=use1"
              onClick={() =>
                sendAnalytics('footer', 'you-link', {
                  click_element: 'Website Feedback',
                  link_url: '/feedback',
                  path: pathname,
                })
              }
              rel="noreferrer"
              sx={{
                fontSize: '14px',
                textTransform: 'none',
                fontFamily: 'aktiv-grotesk',
              }}
              target="_blank"
              variant=""
            >
              Website Feedback
            </Button>
          </Box>
        </Stack>
        {!school.link_to_you ? (
          <Stack
            sx={{
              display: 'flex',
              justifyContent: { xs: 'start', lg: 'end' },
              pr: { lg: 3 },
            }}
          >
            <Box
              sx={{
                ':hover': {
                  backgroundColor: 'card.background-hover',
                },
                justifyContent: 'center',
                backgroundColor: 'surface.container',
                borderRadius: { xs: 0, lg: '16px' },
                display: 'flex',
                flexDirection: 'column',
                fontSize: '15px',
                mb: { xs: -2, lg: 1 },
                padding: '24px 16px',
                textDecoration: 'none',
                whiteSpace: 'nowrap',
                width: { xs: '100%', lg: 'fit-content' },
              }}
            >
              <a
                href={'https://' + school.domain}
                onClick={() =>
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'More Resources',
                    path: pathname,
                  })
                }
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
                target="_blank"
              >
                <Stack
                  sx={{
                    alignItems: 'center',
                    color: 'surface.on-surface',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Typography variant="subtitle1">
                    Find more well-being resources at
                  </Typography>
                  <Box
                    alt={school.name}
                    component="img"
                    height="25px"
                    mt={2}
                    src={school.logoImage}
                  />
                </Stack>
              </a>
            </Box>
          </Stack>
        ) : (
          <Stack mb={{ xs: 4 }} ml={{ xs: 4 }} mr={{ lg: 2 }} spacing={1}>
            <Stack alignItems={'center'} direction={'row'} spacing={0.5}>
              <Icon>language</Icon>
              <Typography
                sx={{
                  color: 'grey.1',
                  display: 'inline-block',
                  fontFamily: 'aktiv-grotesk',
                  fontSize: '15px',
                  fontWeight: 600,
                }}
              >
                ¿Desea cambiar el idioma de su navegador?
              </Typography>
            </Stack>
            <Stack alignItems={'center'} direction={'row'} spacing={0.5}>
              <Icon>download_for_offline</Icon>
              <Link
                href="https://helpcompass.s3.amazonaws.com/Spanish+Browser+Instructions+(1)_es.pdf"
                onClick={() =>
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'Spanish PDF',
                    path: pathname,
                  })
                }
                sx={{
                  color: 'grey.1',
                  fontFamily: 'aktiv-grotesk',
                  fontSize: '15px',
                  textDecorationColor: '#333333',
                }}
                target="blank"
                title="A link to a .pdf file is present and will open in a new browser"
              >
                Descargue la guía en PDF
              </Link>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  )
}
Footer.propTypes = {
  school: PropTypes.object,
}

export default Footer
