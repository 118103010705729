import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import trackEvent from '../utils/trackEvent'
import { useNavigate } from 'react-router-dom'
import Icon from './Icon/Icon'

const Home = ({ school }) => {
  const navigate = useNavigate()

  return (
    <Box sx={{ backgroundColor: 'surface.surface', minHeight: '100vh' }}>
      <Box
        sx={{
          '& .MuiTypography-root': {
            textAlign: { xs: 'left', lg: 'center' },
          },
          alignItems: 'center',
          backgroundColor: school.primary_color,
          backgroundImage: {
            xs: 'url(/images/triage/HC-landing-page-banner.png)',
            lg: 'url(/images/triage/HC-landing-page-banner.png)',
          },
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          display: 'flex',
          height: '40vh',
          justifyContent: 'center',
          pl: { xs: 2, lg: 0 },
        }}
      >
        <Typography
          component={'h1'}
          sx={{ color: '#fff', mt: 8, textAlign: 'center', width: '80%' }}
          variant="displayTitle"
        >
          {school.triage_title
            ? school.triage_title
            : 'Life gets hard. Finding help is now easy.'}
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          mx: 'auto',
          pt: '64px',
          width: { xs: '90%', lg: '50%' },
        }}
      >
        <Stack alignItems={'left'} sx={{ width: 'fit-content' }}>
          <Typography component={'h2'} sx={{ m: 0 }} variant="displaySubtitle">
            Challenges come in many forms.
          </Typography>
          <Typography
            sx={{ mb: '64px', mt: '24px', textAlign: 'left' }}
            variant="subtitle1"
          >
            Answer a few questions to find the right resources, right now.
          </Typography>
          <Button
            onClick={() => {
              navigate('/triage')
              trackEvent('HC Home Screen', {
                data: {},
              })
            }}
            sx={{
              alignSelf: 'end',
              gridGap: '8px',
              px: 2,
              py: 1,
            }}
            variant="home"
          >
            Get Started <Icon sx={{ fontSize: 20 }}>arrow_forward</Icon>
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}

Home.propTypes = {
  school: PropTypes.object.isRequired,
}

export default Home
