import { Box, ListItem, Stack, Typography } from '@mui/material'
import Icon from 'components/Icon/Icon'
import PropTypes from 'prop-types'
import { always, cond, equals } from 'ramda'

const QuestionNavItem = ({ active, step, text }) => {
  const getIcon = cond([
    [equals(1), always({ fill: true, icon: 'person' })],
    [equals(2), always({ fill: true, icon: 'support' })],
    [equals(3), always({ fill: false, icon: 'action_key' })],
  ])
  return (
    <ListItem>
      <Stack
        direction={'row'}
        spacing={1}
        sx={
          active
            ? {
                backgroundColor: 'surface.container-high',
                borderRadius: '100px',
                px: 2,
                py: 1,
                width: '100%',
              }
            : {
                borderRadius: '100px',
                px: 2,
              }
        }
      >
        <Icon fill={getIcon(step).fill} sx={{ fontSize: 20 }}>
          {getIcon(step).icon}
        </Icon>
        <Typography color={'surface.on-surface'} variant="body1">
          Step&nbsp;{step}: {text}
        </Typography>
      </Stack>
    </ListItem>
  )
}

QuestionNavItem.propTypes = {
  active: PropTypes.bool,
  step: PropTypes.number,
  text: PropTypes.string,
}

export default QuestionNavItem
