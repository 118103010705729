import { useContext } from 'react'
import { ResourcesContext } from '../../contexts/resourcesContext'
import PropTypes from 'prop-types'
import { Box, Stack, Typography } from '@mui/material'

const ResourceHeader = ({ pageType = null, school }) => {
  const { needsCopy } = useContext(ResourcesContext)
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: 'space-between',
        mt: 2,
      }}
    >
      <Stack spacing={0} sx={{ mt: 3, width: { xs: '100%', lg: '80%' } }}>
        <Typography component="h2" variant="h2">
          Getting back on track starts with reaching out.&nbsp;
        </Typography>
        <Typography sx={{ mt: '24px' }} variant="subtitle1">
          Here&apos;s what {school.name} has to help with {needsCopy}.
        </Typography>
      </Stack>
    </Box>
  )
}

ResourceHeader.propTypes = {
  pageType: PropTypes.string,
  school: PropTypes.object,
}

export default ResourceHeader
