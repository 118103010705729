import { getNeedsForDisplay } from '../../../utils/getNeedsForDisplay'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import SchoolLogoImage from '../../SchoolLogoImage'

const LetsGoQuestion = ({ triage_logo, needTitles, questionDescription }) => (
  <Grid container spacing={2}>
    <Grid
      item
      md={1}
      sx={{
        display: { xs: 'flex', lg: 'grid' },
        justifyContent: { xs: 'center', lg: 'unset' },
        mr: { xs: 0, lg: 1 },
      }}
      xs={12}
    >
      <SchoolLogoImage logo={triage_logo} />
    </Grid>
    <Grid item md={10} xs={12}>
      <Box>
        <Stack spacing={2}>
          <Typography variant="h4">
            Ok, we’ll match you with resources
            {needTitles.length >= 1 ? ' for ' : '.'}
            {needTitles.length >= 1 ? (
              <span>{getNeedsForDisplay(needTitles)}</span>
            ) : null}
          </Typography>
          {questionDescription && (
            <Typography paragraph variant="body1">
              {questionDescription}
            </Typography>
          )}
          <Typography paragraph variant="body1">
            Just a few more questions to make sure we share the most relevant
            info.
          </Typography>
        </Stack>
      </Box>
    </Grid>
  </Grid>
)

LetsGoQuestion.propTypes = {
  needTitles: PropTypes.array,
  questionDescription: PropTypes.string,
  triage_logo: PropTypes.string,
}

export default LetsGoQuestion
